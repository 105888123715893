<template>
  <div class="container">
    <el-descriptions title="账户信息" :column="1">
      <el-descriptions-item label="名称">{{
        cooperator.title
      }}</el-descriptions-item>
      <el-descriptions-item label="余额">{{
        cooperator.account
      }}</el-descriptions-item>
      <el-descriptions-item label="提现账户">
        <div class="flex al">
          <span> {{ cooperator.account_ali || "未绑定" }}</span>
          <div class="text-btn" @click="openAccount">编辑</div>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <div>
      <el-button @click="cashShow = true" size="small" type="primary"
        >提现</el-button
      >
    </div>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #type>
        <div>提现</div>
      </template>
      <template #finished="{ row }">
        <div>
          <el-tag type="success" size="small" v-if="row.finished"
            >已到账</el-tag
          >
          <el-tag type="info" size="small" v-else>未到账</el-tag>
        </div>
      </template>
    </auto-table>

    <el-dialog title="提现账户" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" label-width="100px">
        <el-form-item label="账户姓名">
          <el-input v-model="aliform.name" placeholder="支付宝实名"></el-input>
        </el-form-item>
        <el-form-item label="支付宝账号">
          <el-input
            v-model="aliform.aliPay"
            placeholder="支付宝账号"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="savePayAccount">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="余额提现" :visible.sync="cashShow" width="30%">
      <el-form ref="form" label-width="100px">
        <el-form-item label="可提现余额">
          <div>{{ cooperator.account }}</div>
        </el-form-item>
        <el-form-item label="提现金额">
          <el-input type="number" :min="0" v-model="cash"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cashShow = false">取 消</el-button>
        <el-button type="primary" @click="aliTransfer" v-loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      cashShow: false,
      loading: false,
      aliform: {
        aliPay: "",
        name: "",
      },
      cooperator: JSON.parse(localStorage.getItem("cooperator") || "{}"),
      cash: 0,
      DataList: [],
      Option: [
        { name: "名称", value: "title" },
        { name: "金额(元)", value: "amount" },
        { name: "类型", value: "type", type: "custom" },
        { name: "状态", value: "finished", type: "custom" },
        { name: "到账时间", value: "finished_time" },
        { name: "提现时间", value: "create_time" },
        { name: "说明", value: "remark" },
      ],
      Total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    //提现
    aliTransfer() {
      if (this.cash == "" || this.cash == 0) {
        this.$message("请填写提现金额");
        return;
      }
      if (this.cooperator.account < this.cash) {
        this.$message("余额不足");
        return;
      }
      if (this.cooperator.account_ali == "") {
        this.$message("请绑定账户");
        return;
      }
      this.loading = true;
      this.$axios({
        url: "/user/pay/coopTransfer",
        method: "post",
        data: {
          id: this.cooperator.id,
          account: this.cash,
        },
      })
        .then((res) => {
          this.loading = false;
          this.cashShow = false;
          this.cash = 0;
          this.$message(res.data.message);
          this.getAgentInfo();
          this.getList();
        })
        .catch((err) => {
          this.loading = false;
          this.cashShow = false;
          this.$message(err.data.message);
        });
    },
    getAgentInfo() {
      this.$axios({
        url: "/user/cooperator/findById",
        params: {
          id: this.cooperator.id,
        },
      }).then((res) => {
        this.cooperator = res.data.data;
        localStorage.setItem("cooperator", JSON.stringify(this.cooperator));
      });
    },
    openAccount() {
      this.aliform.aliPay = this.cooperator.account_ali;
      this.aliform.name = this.cooperator.account_name;
      this.dialogVisible = true;
    },
    savePayAccount() {
      if (!this.aliform.name || !this.aliform.aliPay) {
        this.$message("请填写提现账户信息");
        return;
      }

      this.$axios({
        url: "/user/cooperator/update",
        method: "post",
        data: {
          id: this.cooperator.id,
          account_name: this.aliform.name,
          account_ali: this.aliform.aliPay,
        },
      }).then((res) => {
        this.getAgentInfo();
        this.dialogVisible = false;
      });
    },
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/rechargerecord/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
            cooperator_id: this.cooperator.id,
          rcharge_type: "transfer",
          finished: true,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.text-btn {
  color: rgb(68, 124, 230);
  cursor: pointer;
  margin-left: 10px;
}
</style>